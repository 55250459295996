const tg = window.Telegram.WebApp;
tg.expand();

export function useTelegram() {

    const onClose = () => {
        tg.close()
    }

    const showProgress = () => {
        //tg.
    }

    const onToggleButton = (hide = null) => {
        if (hide !== null) {
            if (tg.MainButton.isVisible) {
                tg.MainButton.hide();
            } else {
                tg.MainButton.show();
            }
        } else {
            if (tg.MainButton.isActive) {
                tg.MainButton.disable();
            } else {
                tg.MainButton.enable();
            }
        }
    }

    return {
        onClose,
        onToggleButton,
        tg,
        user: tg.initDataUnsafe?.user,
        queryId: tg.initDataUnsafe?.query_id,
    }
}
