import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { MdDelete, MdAdd, MdToggleOff, MdToggleOn } from 'react-icons/md';
import Select from 'react-select';
import { toCurrency } from '../../additional';
import { NumericFormat, NumberFormatBase } from 'react-number-format';
import { DateInput } from '../../components/UI';

import { useTelegram } from "../../hooks/useTelegram";

import { CheckBox, Input } from '../../components/UI';
import TgHeader from '../../components/TgHeader';
import ToggleSwitch from '../../components/UI/Toggle/ToggleSwitch';

import './tg.css';
// import { current } from '@reduxjs/toolkit';

const regions = [

];

const TgButton = (props) => {
    return (
        <button {...props} className={'button ' + props.className} />
    );
};

const NewIflForm = () => {
    const { tg } = useTelegram();
    const [searchParams] = useSearchParams();
    const main_menu = searchParams.get("main_menu");
    const clientId = searchParams.get("userId");

    //const [propertyTypes, setPropertyTypes] = useState(['Квартира']);
    const [formData, setFormData] = useState({
        house: {
            selected: false,
            insuranceAmount: {
                constructive: {
                    selected: false,
                    value: 0.0
                },
                finishing: {
                    selected: false,
                    value: 0.0
                },
                movableProperty: {
                    selected: false,
                    value: 0.0
                },
            },
            floorMaterial: null,
            wallMaterial: null,
            yearOfConstruction: 0,
            square: 0.0,
            addressInsuranceObject: "",
            civilResponsibility: 0, //сумма от 300к до 3м
            civilResponsibilitycheck: false,
            renovationWork: false,
            availability: {
                fireplace: false,
                sauna: false,
                gasBottle: false
            }
        },
        apartment: {
            selected: false,
            insuranceAmount: {
                constructive: {
                    selected: false,
                    value: 0.0
                },
                finishing: {
                    selected: false,
                    value: 0.0
                },
                movableProperty: {
                    selected: false,
                    value: 0.0
                },
            },
            yearOfConstruction: 0,
            square: 0.0,
            availability: {
                fireplace: false,
                sauna: false,
                gasBottle: false
            },
            civilResponsibility: 0, //сумма от 300к до 3м
            civilResponsibilitycheck: false,
            addressInsuranceObject: "",
            renovationWork: false,
            apartmentTopFloor: false
        },
        bath: {
            selected: false,
            insuranceAmount: {
                constructive: {
                    selected: false,
                    value: 0.0
                },
                finishing: {
                    selected: false,
                    value: 0.0
                },
                movableProperty: {
                    selected: false,
                    value: 0.0
                },
            },
            floorMaterial: null,
            wallMaterial: null,
            yearOfConstruction: 0,
            square: 0.0,
            addressInsuranceObject: "",
            civilResponsibility: 0, //сумма от 300к до 3м
            civilResponsibilitycheck: false,
            renovationWork: false,
            availability: {
                fireplace: false,
                sauna: false,
                gasBottle: false
            }
        },
        garage: {
            selected: false,
            insuranceAmount: {
                constructive: {
                    selected: false,
                    value: 0.0
                },
                movableProperty: {
                    selected: false,
                    value: 0.0
                },
            },
            floorMaterial: null,
            wallMaterial: null,
            yearOfConstruction: 0,
            square: 0.0,
            addressInsuranceObject: "",
            renovationWork: false,
            availability: {
                fireplace: false,
                sauna: false,
                gasBottle: false
            }
        },
    });

    useEffect(() => {
        let _data = {
            type: "ИФЛ",
            ...formData
        };

        console.log("+++ IFL BEFORE +++ ", _data);

        //data.apartment = { ...formData.apartment };
        //data.garage = { ...formData.garage };
        //data.house = { ...formData.house };
        //data.bath = { ...formData.bath };

        //  площадь
        //_data.apartment.square = _data.apartment.square ? (isNaN(parseFloat(_data.apartment.square?.replace(" ", ""))) ? 0.0 : parseFloat(_data.apartment.square?.replace(" ", ""))) : 0.0;
        //_data.bath.square = _data.bath.square ? (isNaN(parseFloat(_data.bath.square?.replace(" ", ""))) ? 0.0 : parseFloat(_data.bath.square?.replace(" ", ""))) : 0.0;
        //_data.garage.square = _data.garage.square ? (isNaN(parseFloat(_data.garage.square?.replace(" ", ""))) ? 0.0 : parseFloat(_data.garage.square?.replace(" ", ""))) : 0.0;
        //_data.house.square = _data.house.square ? (isNaN(parseFloat(_data.house.square?.replace(" ", ""))) ? 0.0 : parseFloat(_data.house.square?.replace(" ", ""))) : 0.0;
        //  суммы

        //_data.apartment.insuranceAmount.constructive.value = _data.apartment.insuranceAmount.constructive.value ? (isNaN(parseFloat(_data.apartment.insuranceAmount.constructive.value.split(" ").join(""))) ? 0.0 : parseFloat(_data.apartment.insuranceAmount.constructive.value.split(" ").join(""))) : 0.0;
        //_data.bath.insuranceAmount.constructive.value = _data.bath.insuranceAmount.constructive.value ? (isNaN(parseFloat(_data.bath.insuranceAmount.constructive.value.split(" ").join(""))) ? 0.0 : parseFloat(_data.bath.insuranceAmount.constructive.value.split(" ").join(""))) : 0.0;
        //_data.garage.insuranceAmount.constructive.value = _data.garage.insuranceAmount.constructive.value ? (isNaN(parseFloat(_data.garage.insuranceAmount.constructive.value.split(" ").join(""))) ? 0.0 : parseFloat(_data.garage.insuranceAmount.constructive.value.split(" ").join(""))) : 0.0;
        //_data.house.insuranceAmount.constructive.value = _data.house.insuranceAmount.constructive.value ? (isNaN(parseFloat(_data.house.insuranceAmount.constructive.value.split(" ").join(""))) ? 0.0 : parseFloat(_data.house.insuranceAmount.constructive.value.split(" ").join(""))) : 0.0;

        //data.apartment.insuranceAmount.finishing.value = data.apartment.insuranceAmount.finishing.value ? (isNaN(parseFloat(data.apartment.insuranceAmount.finishing.value.split(" ").join(""))) ? 0.0 : parseFloat(data.apartment.insuranceAmount.finishing.value.split(" ").join(""))) : 0.0;
        //data.bath.insuranceAmount.finishing.value = data.bath.insuranceAmount.finishing.value ? (isNaN(parseFloat(data.bath.insuranceAmount.finishing.value.split(" ").join(""))) ? 0.0 : parseFloat(data.bath.insuranceAmount.finishing.value.split(" ").join(""))) : 0.0;
        ////data.garage.insuranceAmount.finishing.value = data.garage.insuranceAmount.finishing.value ? (isNaN(parseFloat(data.garage.insuranceAmount.finishing.value.split(" ").join(""))) ? 0.0 : parseFloat(data.garage.insuranceAmount.finishing.value.split(" ").join(""))) : 0.0;
        //data.house.insuranceAmount.finishing.value = data.house.insuranceAmount.finishing.value ? (isNaN(parseFloat(data.house.insuranceAmount.finishing.value.split(" ").join(""))) ? 0.0 : parseFloat(data.house.insuranceAmount.finishing.value.split(" ").join(""))) : 0.0;

        //data.apartment.insuranceAmount.movableProperty.value = data.apartment.insuranceAmount.movableProperty.value ? (isNaN(parseFloat(data.apartment.insuranceAmount.movableProperty.value.split(" ").join(""))) ? 0.0 : parseFloat(data.apartment.insuranceAmount.movableProperty.value.split(" ").join(""))) : 0.0;
        //data.bath.insuranceAmount.movableProperty.value = data.bath.insuranceAmount.movableProperty.value ? (isNaN(parseFloat(data.bath.insuranceAmount.movableProperty.value.split(" ").join(""))) ? 0.0 : parseFloat(data.bath.insuranceAmount.movableProperty.value.split(" ").join(""))) : 0.0;
        //data.garage.insuranceAmount.movableProperty.value = data.garage.insuranceAmount.movableProperty.value ? (isNaN(parseFloat(data.garage.insuranceAmount.movableProperty.value.split(" ").join(""))) ? 0.0 : parseFloat(data.garage.insuranceAmount.movableProperty.value.split(" ").join(""))) : 0.0;
        //data.house.insuranceAmount.movableProperty.value = data.house.insuranceAmount.movableProperty.value ? (isNaN(parseFloat(data.house.insuranceAmount.movableProperty.value.split(" ").join(""))) ? 0.0 : parseFloat(data.house.insuranceAmount.movableProperty.value.split(" ").join(""))) : 0.0;

        //if (data?.house?.square) data.house.square = isNaN(parseFloat(data.house.square.replace(" ", ""))) ? null : parseFloat(data.house.square.replace(" ", ""));

        console.log("+++ IFL +++ ", _data);

        //console.log(JSON.stringify(data));

    }, [formData]);


    const onSendData = useCallback(() => {
        tg.MainButton.showProgress();

        let data = {
            type: "ИФЛ",
            ...formData
        };

        //data.apartment = { ...formData.apartment };
        //data.garage = { ...formData.garage };
        //data.house = { ...formData.house };
        //data.bath = { ...formData.bath };

        //data.apartment.square = data.apartment.square ? (isNaN(parseFloat(data.apartment.square?.replace(" ", ""))) ? 0.0 : parseFloat(data.apartment.square?.replace(" ", ""))) : 0.0;
        ////data.apartment.insuranceAmount.constructive.value = data.apartment.insuranceAmount.constructive.value ? (isNaN(parseFloat(data.apartment.insuranceAmount.constructive.value.split(" ").join(""))) ? 0.0 : parseFloat(data.apartment.insuranceAmount.constructive.value.split(" ").join(""))) : 0.0;
        ////data.apartment.insuranceAmount.movableProperty.value = data.apartment.insuranceAmount.movableProperty.value ? (isNaN(parseFloat(data.apartment.insuranceAmount.movableProperty.value.split(" ").join(""))) ? 0.0 : parseFloat(data.apartment.insuranceAmount.movableProperty.value.split(" ").join(""))) : 0.0;
        ////data.apartment.insuranceAmount.finishing.value = data.apartment.insuranceAmount.finishing.value ? (isNaN(parseFloat(data.apartment.insuranceAmount.finishing.value.split(" ").join(""))) ? 0.0 : parseFloat(data.apartment.insuranceAmount.finishing.value.split(" ").join(""))) : 0.0;

        ////data.bath.insuranceAmount.constructive.value = data.bath.insuranceAmount.constructive.value ? (isNaN(parseFloat(data.bath.insuranceAmount.constructive.value.split(" ").join(""))) ? 0.0 : parseFloat(data.bath.insuranceAmount.constructive.value.split(" ").join(""))) : 0.0;
        ////data.bath.insuranceAmount.movableProperty.value = data.bath.insuranceAmount.movableProperty.value ? (isNaN(parseFloat(data.bath.insuranceAmount.movableProperty.value.split(" ").join(""))) ? 0.0 : parseFloat(data.bath.insuranceAmount.movableProperty.value.split(" ").join(""))) : 0.0;
        ////data.bath.insuranceAmount.finishing.value = data.bath.insuranceAmount.finishing.value ? (isNaN(parseFloat(data.bath.insuranceAmount.finishing.value.split(" ").join(""))) ? 0.0 : parseFloat(data.bath.insuranceAmount.finishing.value.split(" ").join(""))) : 0.0;
        //data.bath.square = data.bath.square ? (isNaN(parseFloat(data.bath.square?.replace(" ", ""))) ? 0.0 : parseFloat(data.bath.square?.replace(" ", ""))) : 0.0;

        ////data.garage.insuranceAmount.constructive.value = data.garage.insuranceAmount.constructive.value ? (isNaN(parseFloat(data.garage.insuranceAmount.constructive.value.split(" ").join(""))) ? 0.0 : parseFloat(data.garage.insuranceAmount.constructive.value.split(" ").join(""))) : 0.0;
        //data.garage.square = data.garage.square ? (isNaN(parseFloat(data.garage.square?.replace(" ", ""))) ? 0.0 : parseFloat(data.garage.square?.replace(" ", ""))) : 0.0;
        ////data.garage.insuranceAmount.movableProperty.value = data.garage.insuranceAmount.movableProperty.value ? (isNaN(parseFloat(data.garage.insuranceAmount.movableProperty.value.split(" ").join(""))) ? 0.0 : parseFloat(data.garage.insuranceAmount.movableProperty.value.split(" ").join(""))) : 0.0;

        //data.house.square = data.house.square ? (isNaN(parseFloat(data.house.square?.replace(" ", ""))) ? 0.0 : parseFloat(data.house.square?.replace(" ", ""))) : 0.0;
        //data.house.insuranceAmount.constructive.value = data.house.insuranceAmount.constructive.value ? (isNaN(parseFloat(data.house.insuranceAmount.constructive.value.split(" ").join(""))) ? 0.0 : parseFloat(data.house.insuranceAmount.constructive.value.split(" ").join(""))) : 0.0;
        //data.house.insuranceAmount.finishing.value = data.house.insuranceAmount.finishing.value ? (isNaN(parseFloat(data.house.insuranceAmount.finishing.value.split(" ").join(""))) ? 0.0 : parseFloat(data.house.insuranceAmount.finishing.value.split(" ").join(""))) : 0.0;
        //data.house.insuranceAmount.movableProperty.value = data.house.insuranceAmount.movableProperty.value ? (isNaN(parseFloat(data.house.insuranceAmount.movableProperty.value.split(" ").join(""))) ? 0.0 : parseFloat(data.house.insuranceAmount.movableProperty.value.split(" ").join(""))) : 0.0;

        //isNaN(parseFloat(square.replace(" ", ""))) ? null : parseFloat(square.replace(" ", ""))
        //if (data?.apartment?.square) data.apartment.square = isNaN(parseFloat(data.apartment.square.replace(" ", ""))) ? 0.0 : parseFloat(data.apartment.square.replace(" ", ""));
        //if (data?.bath?.square) data.bath.square = isNaN(parseFloat(data.bath.square.replace(" ", ""))) ? 0.0 : parseFloat(data.bath.square.replace(" ", ""));
        //if (data?.garage?.square) data.garage.square = isNaN(parseFloat(data.garage.square.replace(" ", ""))) ? 0.0 : parseFloat(data.garage.square?.replace(" ", ""));
        //if (data?.house?.square) data.house.square = isNaN(parseFloat(data.house.square.replace(" ", ""))) ? 0.0 : parseFloat(data.house.square.replace(" ", ""));

        console.log("IFL DATA: ", data);
        if (main_menu && clientId) {

            const sendDataToController = async () => {
                //console.log(data);
                //console.log(JSON.stringify(data));

                const resp = await fetch(
                    //`https://1b77-178-168-122-93.ngrok-free.app/api/ticket/${clientId}/new_ticket`, {
                    `api/ticket/${clientId}/new_ticket`, {
                    method: "POST",
                    mode: 'cors',
                    //mode: 'no-cors',
                    headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                        'Content-Type': 'application/json',
                    }),
                    body: JSON.stringify({ ...data })
                });

                if (resp) console.log(resp);

                tg.close();
            };

            sendDataToController();

        } else {
            tg.sendData(JSON.stringify(data));
        }

    }, [tg, formData, main_menu, clientId]);


    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [tg, onSendData]);

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'Отправить заявку'
        });
        tg.MainButton.show();
    }, [tg.MainButton]);

    useEffect(() => {
        if (!formData.apartment.selected && !formData.house.selected && !formData.bath.selected && !formData.garage.selected) {
            tg.MainButton.disable();
            tg.MainButton.setParams({
                "color": "#A9A9A9",
                //"textColor": tg.ThemeParams.button_text_color ? tg.ThemeParams.button_text_color : "#FFFFFF"
            });
        } else {
            tg.MainButton.enable();
            tg.MainButton.setParams({
                "color": tg.ThemeParams?.button_color ? tg.ThemeParams.button_color : "#143F6B",
                //"textColor": tg.ThemeParams.button_text_color ? tg.ThemeParams.button_text_color : "#FFFFFF"
            });
        }
    }, [tg.ThemeParams, tg.MainButton, formData]);


    const onSelectPropertyTypesHandler = (value) => {

        if (formData.apartment.selected && (value === "house" || value === "bath" || value === "garage")) {
            //Квартиру и дом выбрать одновременно нельзя
            // alert("Возможные варианты для выбора:\n1) Дом\n2) Баня\n3) Квартира\n4) Гараж\n5) Дом + Баня\n6) Дом + Гараж\n7) Баня + Гараж\n8) Дом + Баня + Гараж");
            alert("Возможные варианты для выбора:\n1) Дом\n2) Баня\n3) Квартира\n4) Дом + Баня");

            return;
        } else if (value === "apartment" && (formData.house.selected || formData.bath.selected || formData.garage.selected)) {
            // alert("Возможные варианты для выбора:\n1) Дом\n2) Баня\n3) Квартира\n4) Гараж\n5) Дом + Баня\n6) Дом + Гараж\n7) Баня + Гараж\n8) Дом + Баня + Гараж");
            alert("Возможные варианты для выбора:\n1) Дом\n2) Баня\n3) Квартира\n4) Дом + Баня");

            return;
        }

        setFormData(state => {
            let current = { ...state };
            current[value].selected = !current[value].selected;

            return current;
        });
    };

    return (<div className="body-tg-form">
        <div className={"form"}>
            <div className='form-tg'>
                {/* <h3>Введите ваши данные</h3> */}
                <label className='label-input-tg'>Вид имущества</label>
                <div className='type-selector-ifl'>
                    <div className={'type-item-ifl' + (formData.apartment.selected ? ' active' : '')} onClick={() => onSelectPropertyTypesHandler("apartment")}>Квартира</div>
                    <div className={'type-item-ifl' + (formData.house.selected ? ' active' : '')} onClick={() => onSelectPropertyTypesHandler("house")}>Дом</div>
                    <div className={'type-item-ifl' + (formData.bath.selected ? ' active' : '')} onClick={() => onSelectPropertyTypesHandler("bath")}>Баня</div>
                    {/* <div className={'type-item-ifl' + (formData.garage.selected ? ' active' : '')} onClick={() => onSelectPropertyTypesHandler("garage")}>Гараж</div> */}
                </div>
            </div>
        </div>
        <div className="form-info">
            {formData.apartment.selected &&
                <div className='driver-info-card'>
                    <div className='driver-info-ID'>Данные о Квартире</div>
                    <InsuranceAmount
                        constructionType="apartment"
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <YearSquare
                        constructionType="apartment"
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <Availability
                        constructionType="apartment"
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <NewPoolIflApartment
                        constructionType="apartment"
                        formData={formData}
                        setFormData={setFormData}
                    />
                </div>
            }
            {formData.house.selected &&
                <div className='driver-info-card'>
                    <div className='driver-info-ID'>Данные о Доме</div>
                    <InsuranceAmount
                        constructionType="house"
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <Material
                        constructionType="house"
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <YearSquare
                        constructionType="house"
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <Availability
                        constructionType="house"
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <NewPoolIfl
                        constructionType="house"
                        formData={formData}
                        setFormData={setFormData}
                    />
                </div>
            }
            {formData.bath.selected &&
                <div className='driver-info-card'>
                    <div className='driver-info-ID'>Данные о Бане</div>
                    <InsuranceAmount
                        constructionType="bath"
                        small
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <Material
                        constructionType="bath"
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <YearSquare
                        constructionType="bath"
                        formData={formData}
                        setFormData={setFormData}
                    />
                    {/* <Availability
                        constructionType="bath"
                        formData={formData}
                        setFormData={setFormData}
                    /> */}
                    <NewPoolIfl
                        constructionType="bath"
                        formData={formData}
                        setFormData={setFormData}
                    />
                </div>
            }
            {formData.garage.selected &&
                <div className='driver-info-card'>
                    <div className='driver-info-ID'>Данные о Гараже</div>
                    <InsuranceAmount
                        constructionType="garage"
                        small
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <Material
                        constructionType="garage"
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <YearSquare
                        constructionType="garage"
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <Availability
                        constructionType="garage"
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <NewPoolIfl
                        constructionType="garage"
                        formData={formData}
                        setFormData={setFormData}
                    />
                </div>
            }
        </div>
    </div>
    );
};
const IflNewPolisData = () => {

    const { onToggleButton, tg } = useTelegram();

    useEffect(() => {
        tg.ready();
    }, [tg]);

    return (<div className="tg-header">
        <>
            <TgHeader name="Заявка" type="ИФЛ" />
            <NewIflForm />
        </>
    </div>
    );
};

export default IflNewPolisData;



const InsuranceAmount = ({ constructionType = null, formData, setFormData = () => { }, small }) => {

    const isDisabled = (type) => {
        return !formData[constructionType].insuranceAmount[type].selected;
    };

    const isSelected = (type) => {
        return formData[constructionType].insuranceAmount[type].selected;
    };

    const currentValue = (type) => {
        return formData[constructionType].insuranceAmount[type].value === 0 ? "" : formData[constructionType].insuranceAmount[type].value;
    };

    const onClickConstructiveHandler = (type) => {
        setFormData(state => {
            let current = { ...state };
            current[constructionType].insuranceAmount[type].selected = !current[constructionType].insuranceAmount[type].selected;
            if (current[constructionType].insuranceAmount[type].selected === false) {
                current[constructionType].insuranceAmount[type].value = 0;
            };
            return current;
        });
    };

    // const onChangeSummHandler = (type, summ) => {
    //     setFormData(state => {
    //         let current = { ...state };
    //         let v = parseInt(summ.replace(/[^0-9]/g, ''));
    //         if (isNaN(v)) v = 0;
    //         //let formattedValue = v.toLocaleString('ru-RU'); 
    //         //current[constructionType].insuranceAmount[type].value = formattedValue;

    //         current[constructionType].insuranceAmount[type].value = v;
    //         return current;
    //     });
    // };
    const onChangeSummHandler = (type, summ) => {
        console.log(summ);
        setFormData(state => {
            let current = { ...state };
             
            current[constructionType].insuranceAmount[type].value = isNaN(parseFloat(summ.split(" ").join(""))) ? 0.0 : parseFloat(summ.split(" ").join(""));
            return current;
        });
    };


    // console.log(formData);
    return (
        <>
            <div className='block-header'>Страховая сумма</div>
            {/* <label className='label-input-tg'>Страховая сумма</label> */}
            <div className='input-radiobutton' onClick={() => { }} >
                <div className='radiobutton'>
                    <ToggleSwitch
                        value={isSelected("constructive")}
                        onChange={() => onClickConstructiveHandler("constructive")}
                        name="Конструктивные элементы"
                    />
                </div>

                <NumericFormat
                    className={`masked-input ${isDisabled("constructive") ? 'masked-disabled-input' : ''}`}
                    disabled={isDisabled("constructive")}
                    type="text"
                    placeholder={'Сумма'}
                    value={currentValue("constructive")}
                    onChange={(e) => onChangeSummHandler("constructive", e.target.value)}
                    thousandSeparator=" "
                    decimalScale={2}
                />
                {/* <Input
                    className={'input'}
                    type="text"
                    disabled={isDisabled("constructive")}
                    placeholder={'Сумма'}
                    value={currentValue("constructive")}
                    onChange={(e) => onChangeSummHandler("constructive", e.target.value)}
                    tgForm={true}
                /> */}
            </div>
            {/* {!small && */}
            <div className='input-radiobutton' onClick={() => { }} >
                <div className='radiobutton'>
                    <ToggleSwitch
                        value={isSelected("finishing")}
                        onChange={() => onClickConstructiveHandler("finishing")}
                        name="Отделка/инженерное оборудование"
                    />
                </div>
                <NumericFormat
                    className={`masked-input ${isDisabled("finishing") ? 'masked-disabled-input' : ''}`}
                    disabled={isDisabled("finishing")}
                    type="text"
                    placeholder={'Сумма'}
                    value={currentValue("finishing")}
                    onChange={(e) => onChangeSummHandler("finishing", e.target.value)}
                    thousandSeparator=" "
                    decimalScale={2}
                //format={currencyFormat}
                />
                {/* <Input
                    className={'input'}
                    type="text"
                    placeholder={'Сумма'}
                    disabled={isDisabled("finishing")}
                    value={currentValue("finishing")}
                    onChange={(e) => onChangeSummHandler("finishing", e.target.value)}
                    tgForm={true}
                /> */}
            </div>
            {/* } */}
            <div className='input-radiobutton' onClick={() => { }} >
                <div className='radiobutton'>
                    <ToggleSwitch
                        value={isSelected("movableProperty")}
                        onChange={() => onClickConstructiveHandler("movableProperty")}
                        name="Движимое имущество"
                    />
                </div>
                <NumericFormat
                    className={`masked-input ${isDisabled("movableProperty") ? 'masked-disabled-input' : ''}`}
                    type="text"
                    disabled={isDisabled("movableProperty")}
                    placeholder={'Сумма'}
                    value={currentValue("movableProperty")}
                    onChange={(e) => onChangeSummHandler("movableProperty", e.target.value)}
                    thousandSeparator=" "
                    decimalScale={2}
                //format={currencyFormat}
                />
                {/* <Input
                    className={'input'}
                    type="text"
                    placeholder={'Сумма'}
                    disabled={isDisabled("movableProperty")}
                    value={currentValue("movableProperty")}
                    onChange={(e) => onChangeSummHandler("movableProperty", e.target.value)}
                    tgForm={true}
                /> */}
            </div>
        </>
    );
}


const Material = ({ constructionType = null, formData, setFormData = () => { } }) => {

    const currentValue = (type) => {
        //console.log(formData[constructionType][type]);
        return formData[constructionType][type] ?? 'select';
    };

    const onChangeHandler = (type, value) => {
        setFormData(state => {
            let current = { ...state };
            current[constructionType][type] = value;

            return current;
        });
    };

    //floorMaterial: null,
    //wallMaterial: null,

    /*const MaterialOptions = () => {
        return (
            <>
                <option disabled value="select"> -- Выбрать материал -- </option>
                <option value="Смешанный">Смешанный</option>
                <option value="Дерево">Дерево</option>
                <option value="Камень">Камень</option>
                <option value="<Нет данных>">&#8826;Нет данных&#8827;</option>
                <option value="Иное">Иное</option>
            </>
        );
    };*/

    // const materialOptions = [
    //     {
    //         value: "Смешанный",
    //         label: "Смешанный"
    //     },
    //     {
    //         value: "Дерево",
    //         label: "Дерево"
    //     },
    //     {
    //         value: "Камень",
    //         label: "Камень"
    //     },
    //     {
    //         value: "<Нет данных>",
    //         label: "<Нет данных>"
    //     },
    //     {
    //         value: "Иное",
    //         label: "Иное"
    //     },
    // ];
    const materialOptions = [
        {
            value: "Смешанный",
            label: "Смешанный"
        },
        {
            value: "Горючий",
            label: "Горючий"
        },
        {
            value: "Негорючий",
            label: "Негорючий"
        },
    ];


    return (
        <>
            <div className='input-selector' onClick={() => { }} >
                <label className='label-input-tg' htmlFor='floorMaterial'>Материал перекрытий</label>
                <Select
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? '#50A7EA' : '#DDE2E4',
                            boxShadow: state.isFocused ? 'none' : null,
                        }),
                    }}
                    name="floorMaterial" id="floorMaterial"
                    value={materialOptions.find(e => e.value === currentValue("floorMaterial"))}
                    onChange={(e) => onChangeHandler("floorMaterial", e.value)}
                    options={materialOptions}
                    placeholder=" -- Выбрать материал -- "
                    isSearchable={false}
                />
            </div>
            <div className='input-selector' onClick={() => { }} >
                <label className='label-input-tg' htmlFor='wallMaterial'>Материал стен</label>
                <Select
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? '#50A7EA' : '#DDE2E4',
                            boxShadow: state.isFocused ? 'none' : null,
                        }),
                    }}
                    name="wallMaterial" id="wallMaterial"
                    value={materialOptions.find(e => e.value === currentValue("wallMaterial"))}
                    onChange={(e) => onChangeHandler("wallMaterial", e.value)}
                    options={materialOptions}
                    placeholder=" -- Выбрать материал -- "
                />
            </div>
        </>
    );
};

const YearSquare = ({ constructionType = null, formData, setFormData = () => { } }) => {

    //yearOfConstruction: "",
    //square: "",

    const currentValue = (type) => {
        return formData[constructionType][type] === "" ? "" : formData[constructionType][type];
    };

    const onChangeHandler = (type, value) => {
        setFormData(state => {
            let current = { ...state };
            let v = parseInt(value);

            if (isNaN(v)) v = 0;
            current[constructionType][type] = v;

            return current;
        });
    };
    const onChangeHandlerSquare = (type, value) => {
        setFormData(state => {
            let current = { ...state };
            //const v = isNaN(parseFloat(current[constructionType][type].replace(" ", ""))) ? 0.0 : parseFloat(current[constructionType][type].replace(" ", ""));
            //current[constructionType][type] = v;
            current[constructionType][type] = isNaN(parseFloat(value.replace(/ /g, ''))) ? 0.0 : parseFloat(value.replace(/ /g, ''));
            return current;
        });
    };


    return (
        <>
            {/* <label className='label-input-tg'>Год постройки</label> */}
            {/* <MaskedInput
                mask={[/\d/, /\d/, /\d/, /\d/]}
                className={'masked-input'}
                type="text"
                placeholder={'Год постройки (1990)'}
                value={currentValue("yearOfConstruction")}
                onChange={(e) => onChangeHandler("yearOfConstruction", e.target.value)}
            /> */}
            <DateInput
                onChange={(e) => onChangeHandler("yearOfConstruction", e.target.value)}
                value={currentValue("yearOfConstruction") === 0 ? "" : currentValue("yearOfConstruction")}
                label="Год постройки"
                placeholder="Год постройки (1990)"
                onlyyear
            />
            <label className='label-input-tg'>Введите площадь постройки</label>
            <NumericFormat
                className={'masked-input'}
                //type="number"
                placeholder={'Площадь постройки, м2'}
                value={currentValue("square") === 0 ? "" : currentValue("square")}
                onChange={(e) => onChangeHandlerSquare("square", e.target.value)}
                thousandSeparator=" "
                decimalScale={2}
            />
            {/* <Input
                className={'input'}
                type="number"
                placeholder={'Площадь постройки, м2'}
                value={currentValue("square")}
                onChange={(e) => onChangeHandlerSquare("square", e.target.value)}
                tgForm={true}
            /> */}
        </>
    );
};

const Availability = ({ constructionType = null, formData, setFormData = () => { } }) => {

    //availability: {
    //  fireplace: false,
    //  sauna: false,
    //  gasBottle: false
    //}
    const isSelected = (type) => {
        return formData[constructionType].availability[type];
    };

    const onChangeHandler = (type) => {
        setFormData(state => {
            let current = { ...state };
            current[constructionType].availability[type] = !current[constructionType].availability[type];

            return current;
        });
    };

    return (
        <>
            {constructionType !== 'apartment' && ( // Проверяем, не является ли тип строения apar
                <>
                    <div className='availability-select'>
                        <ToggleSwitch
                            value={isSelected("fireplace")}
                            onChange={() => onChangeHandler("fireplace")}
                            name="Камин"
                        />
                    </div>
                    <div className='availability-select'>
                        <ToggleSwitch
                            value={isSelected("sauna")}
                            onChange={() => onChangeHandler("sauna")}
                            name="Сауна"
                        />
                    </div>
                    <div className='availability-select'>
                        <ToggleSwitch
                            value={isSelected("gasBottle")}
                            onChange={() => onChangeHandler("gasBottle")}
                            name="Газовый баллон"
                        />
                    </div>
                </>
            )}
        </>
    );
};

const NewPoolIfl = ({ constructionType = null, formData, setFormData = () => { } }) => {


    // addressInsuranceObject: 0,
    // civilResponsibilitycheck: false,
    // renovationWork: false,

    const currentAddressInsuranceObject = () => {
        return formData[constructionType].addressInsuranceObject === "" ? "" : formData[constructionType].addressInsuranceObject;
    };

    const onChangeAddressInsuranceObjectHandler = (value) => {
        setFormData(state => {
            let current = { ...state };
            current[constructionType].addressInsuranceObject = value;
            return current;
        });
    };

    const isSelected = (type) => {
        return formData[type].civilResponsibilitycheck;
    };

    const onChangeHandler = ({ type }) => {
        console.log("civil", constructionType);
        setFormData(state => {
            let current = { ...state };
            current[constructionType].civilResponsibilitycheck = !current[constructionType].civilResponsibilitycheck;

            return current;
        });
    };

    const isSelectedRenovationWork = (type) => {
        // console.log("typeselected",type);
        return formData[type]?.renovationWork;
    };

    const onChangeHandlerRenowationWork = ({ }) => {
        // console.log("TYPE", id);
        const type = constructionType.replace("-renovation", "");
        setFormData(state => {
            let current = { ...state };
            current[constructionType].renovationWork = !current[constructionType].renovationWork;

            return current;
        });
    };
    const currentСivilResponsibility = () => {
        return formData[constructionType].civilResponsibility;
    };

    // const onChangeСivilResponsibilityHandler = (value) => {
    //     setFormData(state => {
    //         let current = { ...state };
    //         // let v = parseInt(value);
    //         // if (isNaN(v)) v = 0;
    //         let v = parseInt(value.replace(/[^0-9]/g, ''));
    //         if (isNaN(v)) v = 0;
    //         current[constructionType].civilResponsibility = v;
    //         return current;
    //     });
    // };
    const onChangeСivilResponsibilityHandler = (value) => {
        setFormData(state => {
            let current = { ...state };
            current[constructionType].civilResponsibility = isNaN(parseFloat(value.replace(/ /g, ''))) ? 0.0 : parseFloat(value.replace(/ /g, ''));
            return current;
        });
    };


    return (
        <>
            <label className='label-input-tg'>Введите адрес объекта страхования</label>
            <Input
                className={'input'}
                type="text"
                placeholder={'Адрес объекта страхования'}
                value={currentAddressInsuranceObject()}
                onChange={(e) => onChangeAddressInsuranceObjectHandler(e.target.value)}
                tgForm={true}
            />

            {constructionType !== 'garage' && ( // Проверяем, не является ли тип строения гаражом
                <>
                    <div className='check-box-civil-tg'>
                        <ToggleSwitch
                            name='Гражданская ответственность'
                            value={isSelected(constructionType)}
                            disabled={false}
                            id={constructionType}
                            onChange={onChangeHandler}
                            tgForm={true}
                        />
                    </div>
                    {isSelected(constructionType) && ( // Проверяем, что галочка Гражданская ответственность включена
                        <>
                            {/* <Input
                                className={'input'}
                                type="text"
                                placeholder={'Гражданская отв-ность (500т-3млн)'}
                                value={currentСivilResponsibility()}
                                onChange={(e) => onChangeСivilResponsibilityHandler(e.target.value)}
                                tgForm={true}
                            /> */}
                            <NumericFormat
                                className={'masked-input'}
                                type="text"
                                placeholder={'Гражданская отв-ность (500т-3млн)'}
                                value={currentСivilResponsibility()}
                                onChange={(e) => onChangeСivilResponsibilityHandler(e.target.value)}
                                thousandSeparator=" "
                                decimalScale={2}
                            //format={currencyFormat}
                            />
                        </>
                    )}
                </>
            )}

            {constructionType !== 'bath' && ( // Проверяем, что тип строения не является баней
                <ToggleSwitch
                    name='Проводятся ли ремонтные работы ?'
                    value={isSelectedRenovationWork(constructionType)}
                    disabled={false}
                    id={constructionType + "-renovation"}
                    onChange={onChangeHandlerRenowationWork}
                    tgForm={true}
                />
            )}
        </>
    );

};

const NewPoolIflApartment = ({ constructionType = null, formData, setFormData = () => { } }) => {

    const currentAddressInsuranceObject = () => {
        return formData[constructionType].addressInsuranceObject === 0 ? "" : formData[constructionType].addressInsuranceObject;
    };

    const onChangeAddressInsuranceObjectHandler = (value) => {
        setFormData(state => {
            let current = { ...state };
            current[constructionType].addressInsuranceObject = value;
            return current;
        });
    };

    const currentСivilResponsibility = () => {
        //console.log(formData[constructionType][type]);
        return formData.apartment.civilResponsibility === "" ? "" : formData.apartment.civilResponsibility;
    };

    // const onChangeСivilResponsibilityHandler = (value) => {
    //     setFormData(state => {
    //         let current = { ...state };
    //         // let v = parseInt(value);
    //         // if (isNaN(v)) v = 0;
    //         let v = parseInt(value.replace(/[^0-9]/g, ''));
    //         if (isNaN(v)) v = 0;
    //         current.apartment.civilResponsibility = v;
    //         //console.log(value, current.apartment.civilResponsibility);
    //         return current;
    //     });
    // };
    const onChangeСivilResponsibilityHandler = (value) => {
        setFormData(state => {
            let current = { ...state };
            current[constructionType].civilResponsibility = isNaN(parseFloat(value.replace(/ /g, ''))) ? 0.0 : parseFloat(value.replace(/ /g, ''));
            return current;
        });
    };


    const isSelected = (type) => {
        // console.log(type);
        return formData[type]?.civilResponsibilitycheck;
    };

    const onChangeHandler = ({ type }) => {
        // console.log("TYPE",id);
        setFormData(state => {
            let current = { ...state };
            current.apartment.civilResponsibilitycheck = !current.apartment.civilResponsibilitycheck;

            return current;
        });
    };

    const isSelectedTopFloor = (type) => {
        // console.log("typeselected",type);
        return formData[type]?.apartmentTopFloor;
    };

    const onChangeHandlerTopFloor = ({ type }) => {
        // console.log("TYPE",id);
        setFormData(state => {
            let current = { ...state };
            current.apartment.apartmentTopFloor = !current.apartment.apartmentTopFloor;

            return current;
        });
    };

    const isSelectedRenovationWork = (type) => {
        // console.log("typeselected",type);
        return formData[type]?.renovationWork;
    };

    const onChangeHandlerRenowationWork = ({ type }) => {
        // console.log("TYPE",id);
        setFormData(state => {
            let current = { ...state };
            current.apartment.renovationWork = !current.apartment.renovationWork;

            return current;
        });
    };

    return (
        <>
            <div className='check-box-civil-tg'>
                <ToggleSwitch
                    name='Гражданская ответственность'
                    value={isSelected(constructionType)}
                    disabled={false}
                    // onChange={() => console.log("hjgdfjdfg")}
                    id={constructionType}
                    onChange={onChangeHandler}
                    tgForm={true}
                />
            </div>
            {isSelected(constructionType) && ( // Проверяем, что галочка Гражданская ответственность включена
                <>
                    {/* <Input
                        className={'input'}
                        type="text"
                        placeholder={'Гражданская отв-ность (500т-3млн)'}
                        value={currentСivilResponsibility()}
                        onChange={(e) => onChangeСivilResponsibilityHandler(e.target.value)}
                        tgForm={true}
                    /> */}
                    <NumericFormat
                        className={'masked-input'}
                        type="text"
                        placeholder={'Гражданская отв-ность (500т-3млн)'}
                        value={currentСivilResponsibility() === 0 ? "" : currentСivilResponsibility()}
                        onChange={(e) => onChangeСivilResponsibilityHandler(e.target.value)}
                        thousandSeparator=" "
                        decimalScale={2}
                    //format={currencyFormat}
                    />
                </>
            )}
            <label className='label-input-tg'>Введите адрес объекта страхования</label>
            <Input
                className={'input'}
                type="text"
                placeholder={'Адрес объекта страхования'}
                value={currentAddressInsuranceObject()}
                onChange={(e) => onChangeAddressInsuranceObjectHandler(e.target.value)}
                tgForm={true}
            />
            <div className='availability-select'>
                <ToggleSwitch
                    name='Квартира находятся на последнем этаже?'
                    value={isSelectedTopFloor(constructionType)}
                    disabled={false}
                    id={constructionType}
                    onChange={onChangeHandlerTopFloor}
                    tgForm={true}
                />
            </div>
            <div className='availability-select'>
                <ToggleSwitch
                    name='Проводятся ли ремонтные работы?'
                    value={isSelectedRenovationWork(constructionType)}
                    disabled={false}
                    id={constructionType}
                    onChange={onChangeHandlerRenowationWork}
                    tgForm={true}
                />
            </div>
        </>
    );
};