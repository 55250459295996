import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTelegram } from "../hooks/useTelegram";

const TgHeader = ({ name, type }) => {
    const { user, onClose, tg } = useTelegram();
    const navigate = useNavigate();

    const onClickBackButtonHandler = () => {
        tg.MainButton.hide();
        navigate(-1);
    };

    useEffect(() => {
        tg.onEvent('backButtonClicked', onClickBackButtonHandler);
        return () => {
            tg.offEvent('backButtonClicked')
        }
    }, [tg]);

    return (
        <div className={'tg-header-row'}>
            {/*<TgButton onClick={onClose}>Закрыть</TgButton>*/}
            <span className={'username'}>
                {name && name}
            </span>
            <span className={'tg-header-type'}>
                {type && type}
            </span>
        </div>
    );
}

export default TgHeader;
