import React, { useId, useState, useRef, useImperativeHandle, useEffect, forwardRef } from 'react';
import { ClipLoader } from 'react-spinners';

import { Icon } from '../UI';

import './Upload.modules.css';


export const upload = (fileType, file, url, options) => {
    // Вытащили xhr из Promise, чтобы прокинуть abort
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';

    const onProgress = options?.onProgress;

    const promise = new Promise((resolve, reject) => {
        xhr.open('POST', url);

        xhr.upload.onprogress = (event) => {
            onProgress?.(Math.round((event.loaded / event.total) * 100));
        };

        xhr.onload = () => {
            if (xhr.status === 200) {
                //console.log(xhr.response);
                resolve(xhr.response);
            }
            else reject(xhr.response);
        };

        const myData = new FormData();
        myData.append(fileType, file);

        xhr.send(myData);
    });

    // Присвоили свойство abort, которое прервет запрос
    promise.abort = () => xhr.abort();

    return promise;
};


const Upload = forwardRef(({
    onUpload,
    fileType,
    ticketId,
    disabled,
    overlay = true,
    children,
    className,
    uploadUrl
}, ref) => {

    const url = uploadUrl ? uploadUrl : (ticketId ? `/api/ticket/${ticketId}/uploadFile` : `/api/additional/uploadFile`);

    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    const reset = () => {
        setLoading(false);
        setProgress(0);
    }

    const abortUploading = useRef();

    const abort = () => {
        abortUploading.current?.();
        reset();
    };

    const handleFile = (fileType, file) => {
        // Если уже загружаем файл или файла нет - ничего не делать 
        if (loading || !file) return;

        setLoading(true);
        // onProgress будет изменять состояние progress
        const uploading = upload(fileType, file, url, { onProgress: setProgress });
        uploading
            .then((e) => {
                //console.log("upload comleate", e);
                onUpload(e);
            })
            .catch((e) => { })
            .finally(reset) // Хоть ошибка, хоть успех - сбрасываем загрузку и прогресс
    };

    const handleFileChange = (event) => {
        handleFile(fileType, event.target.files[0]);
    };

    const input = useRef();

    useImperativeHandle(ref, () => ({
        // upload открывает документы для выбора файлов, другими словами
        // это то же самое что и нажатие на input
        upload: () => input.current?.click(),
        // функция abort уже готова, она сбрасывает отображение компонента
        abort,
        id: input.current?.id
    }));

    const id = useId();
    useEffect(() => {
        //console.log("file id", id);
    }, [id]);

    return (
        <div className="upload" style={{ display: 'flex', position: 'relative' }}>
            {loading &&
                <div style={{ display: 'flex', position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: 8, overflow: 'hidden' }}>
                    <ClipLoader
                        color="#50A7EA"
                        loading
                        size={24}
                    />
                </div>
            }
            {children}
            <label htmlFor={id} className="upload-label">
                <input
                    ref={input}
                    disabled={disabled}
                    type="file"
                    className="upload-input"
                    onChange={handleFileChange}
                    id={id}
                />
                {/*<UploadOutlined className={s.icon} />*/}
            </label>
            {/*{loading && (*/}
            {/*    <div className="loading" style>*/}
            {/*        <ClipLoader*/}
            {/*            color="#36d7b7"*/}
            {/*            loading*/}
            {/*            size={14}*/}
            {/*        />*/}
            {/*        <LoadingOutlined className={s.icon} />*/}
            {/*        <progress value={progress} className={"upload-progress"} />*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
});

export default Upload;
