import React, { useEffect, useCallback, useState, useRef } from "react";
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";

import { useNavigate } from 'react-router-dom';
import { useTelegram } from "../../hooks/useTelegram";
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
//import { useTelegramBackRoutes } from '../../hooks/useTelegramBack';
import { requestTypeNameMenu } from '../../additional';
import TgHeader from '../../components/TgHeader';
import { ReferalLink } from "../../components/UI";
import { Icon } from "../../components/UI/index";
import { Button } from "../../components/UI/StyledButton/StyledButton";
import { toCurrency, stageName, requestTypeName } from '../../additional';

import { fetchProfileData } from '../../store/profileSlice';

export const MainMenu = () => {
    const params = useParams();
    const tg_user_id = params?.id;
    const navigate = useNavigate();
    const { tg } = useTelegram();
    const { height, width } = useWindowDimensions();
    const [showMenu, setShowMenu] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const [navigationData, setNavigationData] = useState(null);
    const [initials, setInitials] = useState("");
    const dispatch = useDispatch();
    const { balance, completedCount, unCompletedCount, friendsCount } = useSelector(state => state.profile);

    useEffect(() => {
        if (tg_user_id)
            dispatch(fetchProfileData(tg_user_id));
    }, [tg_user_id]);

    const onCloseHandler = () => {
        setShowMenu(false);
        setNavigationData(null);
    }

    const onClickMenuHandler = (type) => {
        if (type) {
            setNavigationData({
                name: requestTypeNameMenu(type),
                //requestType: type,
                link: type
            });
            setShowMenu(true);
        } else {
            setNavigationData(null);
        }
    };

    //const backRoute = useTelegramBackRoutes(true);
    //console.log("back button route: ",backRoute);
    //useEffect(() => {
    //    //if (tg_user_id)
    //    //    dispatch(fetchProfileData(tg_user_id));
    //    tg.MainButton.enable();
    //    tg.MainButton.setParams({
    //        "color": tg.ThemeParams?.button_color ? tg.ThemeParams.button_color : "#143F6B",
    //        //"textColor": tg.ThemeParams.button_text_color ? tg.ThemeParams.button_text_color : "#FFFFFF"
    //    });
    //}, [tg_user_id]);

    useEffect(() => {
        tg.ready();
        tg.BackButton.hide();
        //tg.MainButton.enable();
    }, [tg]);


    //const onSendData = () => {
    //    console.log("send event");
    //    try {
    //        const data = {
    //            ppp: "dsfsd"
    //        };

    //        //console.log(JSON.stringify(data));
    //        let res = tg.sendData(JSON.stringify(data));
    //        console.log("data sent", res);
    //    } catch (e) {
    //        //setError(e.toString())
    //        console.log(e);
    //    }
    //};

    useEffect(() => {
        const first_name = tg?.initDataUnsafe?.user?.first_name ?? null;
        const last_name = tg?.initDataUnsafe?.user?.last_name ?? null;
        if (first_name || last_name) {
            setInitials((first_name?.toUpperCase().slice(0, 1) ?? "") + (last_name?.toUpperCase().slice(0, 1) ?? ""));
        }
        console.log(tg?.initDataUnsafe);
    }, [tg?.initDataUnsafe?.user]);

    //useEffect(() => {
    //    setTimeout(() => {
    //        console.log(showMenu);
    //        setShowMenu(!showMenu);
    //    }, 1000);
    //}, [showMenu]);

    useEffect(() => {
        if (showPopover === true) {
            setTimeout(() => {
                setShowPopover(false);
            }, 1000);
        }
    }, [showPopover]);

    return (
        <div className='tg-background' style={{ minHeight: height }}>
            <TgHeader type="Главное меню" />
            <div className="tg-main-menu-container">
                <div className="tg-profile" onClick={() => {
                    tg.BackButton.show();
                    navigate(`/tg/Profile/${tg_user_id}`, { state: { previousPage: "/tg/MainMenu" } });
                }}>
                    <div className="tg-profile-row">
                        <div className="tg-profile-img">
                            {initials}
                        </div>
                        <div className="tg-profile-info">
                            <div className="profile-text-tg">
                                Профиль
                            </div>
                            <div className="profile-balance-tg">
                                Баланс: {toCurrency(balance, 'RUB', 'Ru-ru')}
                            </div>
                        </div>
                    </div>
                    <div className="tg-profile-arrow">
                        <Icon name="chevron-right" />
                    </div>
                </div>
                <div className="services-text">УСЛУГИ</div>
                <div className="tg-services">
                    <div className="tg-main-menu-button" onClick={() => {
                        onClickMenuHandler("kasko");
                        //    tg.BackButton.show();
                        //    navigate(`/tg/SubMenu/${tg_user_id}/kasko`, { state: { previousPage: "/tg/MainMenu", type: "kasko" } });
                    }}>
                        <div className="icon-car-tg"><Icon name="coupe-car" /></div>
                        <div>КАСКО</div>
                    </div>
                    <div className="tg-main-menu-button" onClick={() => {
                        onClickMenuHandler("osago");
                        //tg.BackButton.show();
                        //navigate(`/tg/SubMenu/${tg_user_id}/osago`, { state: { previousPage: "/tg/MainMenu", type: "osago" } });
                    }}>
                        <div className="icon-car-tg"><Icon name="coupe-car" /></div>
                        <div>ОСАГО</div>
                    </div>
                    <div className="tg-main-menu-button" onClick={() => {
                        onClickMenuHandler("ifl");
                        //    tg.BackButton.show();
                        //    navigate(`/tg/SubMenu/${tg_user_id}/ifl`, { state: { previousPage: "/tg/MainMenu", type: "ifl" } });
                    }}>
                        <Icon name="home-03" />
                        <div>ИФЛ</div>
                    </div>
                    <div className="tg-main-menu-button" onClick={() => {
                        onClickMenuHandler("ipoteka");
                        //tg.BackButton.show();
                        //navigate(`/tg/SubMenu/${tg_user_id}/ipoteka`, { state: { previousPage: "/tg/MainMenu", type: "ipoteka" } });
                    }}>
                        <Icon name="home-03" />
                        <div>Ипотека</div>
                    </div>
                    <div className="tg-main-menu-button tg-in_devel-mode" onClick={() => {
                        setShowPopover(true);
                    }}>
                        <div className="tg-in-devel-label">Скоро</div>
                        <Icon name="briefcase" />
                        <div>Другое</div>
                    </div>
                    <div className="tg-main-menu-button tg-in_devel-mode" onClick={() => {
                        setShowPopover(true);
                    }}>
                        <div className="tg-in-devel-label">Скоро</div>
                        <Icon name="question" />
                        <div>Помощь</div>
                    </div>
                </div>
                <ReferalLink />
                {showPopover && <Popover message="Данный раздел находится в разработке" />}
                <ModalMenu tg_user_id={tg_user_id} showMenu={showMenu} navigationData={navigationData} onClick={onClickMenuHandler} onCloseHandler={onCloseHandler} />
            </div>

        </div>

    );
};

export default MainMenu;

export const ModalMenu = ({ showMenu = false, tg_user_id, navigationData, onCloseHandler }) => {
    const { tg } = useTelegram();
    const navigate = useNavigate();
    const [isUploading, setIsUploading] = useState(false);
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);
    const selectFilesRef = useRef(null);

    const [files, setFiles] = useState([]);
    //const params = useParams();
    //const requestTypeName = params?.type;
    useEffect(() => {
        //if (navigationData )
        //    navigate(`/tg/${tg_user_id}/${navigationData.link}/new/uploadForm`, { state: { previousPage: "/tg/MainMenu", type: navigationData.link } });

    }, [files, navigationData]);

    const handleFileChange = ({ target }) => {
        if (target.files) {
            //setStatus('initial');
            setIsUploading(true);
            //setFiles(target.files);
            setStatus(null);

            handleUpload(target.files)
                .then((resp) => {
                    console.log("UPLOADED FILES");
                    ///tg/:type/new/uploadForm
                    setIsUploading(false);
                    setFiles(resp);
                    setStatus('success');
                    navigate(`/tg/${tg_user_id}/${navigationData.link}/new/uploadForm`, { state: { previousPage: "/tg/MainMenu", type: navigationData.link } });

                })
                .catch((e) => {
                    console.log(e);
                    setError(e);
                    setIsUploading(false);
                    setStatus('fail');
                });

            //navigate(`tg/${tg_user_id}/${navigationData.link}/new/uploadForm`, { state: { previousPage: "/tg/MainMenu", type: navigationData.link } });

        }
    };

    const handleUpload = (files, options) => {
        if (files) {
            //setStatus('uploading');

            // Вытащили xhr из Promise, чтобы прокинуть abort
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'json';

            const onProgress = options?.onProgress;

            const promise = new Promise((resolve, reject) => {
                xhr.open('POST', "api/additional/UploadTempFiles");

                xhr.upload.onprogress = (event) => {
                    onProgress?.(Math.round((event.loaded / event.total) * 100));
                };

                xhr.onload = () => {
                    if (xhr.status === 200) {
                        //console.log(xhr.response);
                        resolve(xhr.response);
                    }
                    else reject({
                        status: xhr.status,
                        statusText: xhr.statusText
                    });
                };

                xhr.onerror = function () {
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText
                    });
                };

                const formData = new FormData();
                [...files].forEach((file) => {
                    console.log(file);
                    formData.append('files', file);
                });
                formData.append('UserId', tg_user_id);
                formData.append('requestTypeId', navigationData.name);
                xhr.send(formData);
            });

            // Присвоили свойство abort, которое прервет запрос
            promise.abort = () => xhr.abort();

            return promise;

            //setStatus(null);
            //const formData = new FormData();

            //[...files].forEach((file) => {
            //    formData.append('files', file);
            //});
            //formData.append('UserId', tg_user_id);
            //formData.append('requestTypeId', navigationData.name);

            //try {
            //    const result = await fetch('/api/additional/UploadTempFiles', {
            //        method: 'POST',
            //        body: formData,
            //    });

            //    const data = await result.json();

            //    console.log(data);
            //    setStatus('success');
            //    //tg.close();
            //} catch (error) {
            //    console.error(error);
            //    setStatus('fail');
            //}
        }
    };

    return (
        <div className={"modal-menu-container " + (showMenu ? "active" : "")}>
            <input hidden ref={selectFilesRef} id="file" type="file" multiple onChange={handleFileChange} />
            {isUploading && <Popover message="Дождитесь отправки файлов" />}
            <div className="modal-menu-items-container" >
                <div className="modal-menu-header"><div></div>{navigationData?.name.toUpperCase()}<Icon name="x-close" fill="black" onClick={onCloseHandler} /></div>
                <div className="modal-menu-header-text-container">
                    <Icon name="info-circle" style={{ width: 40 }} />
                    <div className="modal-menu-header-text">
                        <div>
                            Если у вас есть действующий (предыдущий) полис страхования, заполнять анкету необязательно, просто нажмите «Прикрепить файл»
                        </div>
                        <div>
                            Чтобы создать заявку без загрузки полиса, нажмите 
                            <br/>«Ввести данные»
                        </div>
                        <div>
                            Чтобы посмотреть текущие полисы, нажмите <br/>«Мои полисы»
                            <div>
                            Чтобы посмотреть полисы на стадии оформления, нажмите "Мои заявки"
                            </div>
                        </div>
                    </div>
                </div>
                <ButtonUpload leftIconName="attachment" name="Прикрепить файл" isUploading={isUploading} onClick={() => {
                    //tg.close();
                    if (selectFilesRef.current) {
                        selectFilesRef.current.click();
                    };
                }} />

                {/*<Result status={status} />*/}
                {/*{error && <>{JSON.stringify(error)}</>}*/}

                <Button tgFormBlue name="Ввести данные" leftIconName="arrow-right" onClick={() => {
                    tg.BackButton.show();
                    navigate(`/tg/${navigationData?.link}/new?userId=${tg_user_id}&main_menu=1`, { state: { previousPage: `/tg/MainMenu/${tg_user_id}` } });
                }} />
                <div className="tg-button-horizontal">
                    <Button tgFormWhite name="Мои полисы" leftIconName="folder" style={{ width: "100%" }} onClick={() => {
                        tg.BackButton.show();
                        navigate(`/tg/${navigationData?.link}/insurances/${tg_user_id}/`, { state: { previousPage: `/tg/MainMenu/${tg_user_id}` } });
                    }} />
                    <Button tgFormWhite name="Мои заявки" leftIconName="folder" style={{ width: "100%" }} onClick={() => {
                        tg.BackButton.show();
                        navigate(`/tg/${navigationData?.link}/MyOffers/${tg_user_id}/`, { state: { previousPage: `/tg/MainMenu/${tg_user_id}` } });
                    }} />
                </div>

            </div>
        </div>
    );
};

export const ButtonUpload = ({ isUploading, disabled, name, buttonType = "tg-blue", leftIconName, onClick = () => { } }) => {
    console.log(isUploading);
    return (
        <div className={"buttonUploadContainer " + buttonType + (isUploading || disabled ? " button-disabled" : "")} onClick={(isUploading || disabled) === true ? () => { } : onClick}>
            {leftIconName &&
                <Icon name={leftIconName} />
            }
            <div>{name}</div>
            {isUploading &&
                <ClipLoader
                    className="button_spinner"
                    color="#50A7EA"
                    loading={isUploading}
                    size={24}
                />
            }
        </div>
    );
};

export const Popover = ({ message }) => {
    return (
        <div className="custom-popover">
            <div className="popover-text">
                {message}
            </div>
        </div>
    );
}

const Result = ({ status }) => {
    if (status === "success") {
        return <p>✅ File uploaded successfully!</p>;
    } else if (status === "fail") {
        return <p>❌ File upload failed!</p>;
    } else if (status === "uploading") {
        return <p>⏳ Uploading selected file...</p>;
    } else {
        return null;
    }
};